/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(function(){
          $('.main-nav').slicknav({'allowParentLinks': true});
        });

        $(".graph-one").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "-80&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ultra Low Temp" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Biological and Clinical Trial Samples, DNA, RNA, Urine, Serum, Plasma. Legal and pathology samples.").fadeIn(500);
          });
        });

        $(".graph-two").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "- 20&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Freezer" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Retain and Reference Pharmaceuticals.").fadeIn(500);
          });
        });

        $(".graph-three").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "2 - 8&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Refrigerated" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("API Storage. Retain and Reference Pharmaceuticals.").fadeIn(500);
          });
        });

        $(".graph-four").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "15 - 25&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ambient" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals, Bulk storage, Heritage Storage.").fadeIn(500);
          });
        });

        $(".graph-five").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "25 - 60%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long Term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical devices.").fadeIn(500);
          });
        });

        $(".graph-six").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 65%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Intermediate and <br> Long term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical Devices.").fadeIn(500);
          });
        });

        $(".graph-seven").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 75%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long term ICH Zone IVb" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage.").fadeIn(500);
          });
        });

        $(".graph-eight").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "40 - 75%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Accelerated ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals Accelerated testing. Stress testing and Forced degradation studies. Medical devices.").fadeIn(500);
          });
        });


        $(".zone-one").click(function(){
            // console.log("zone one");
            $("#stability-content").fadeOut(500, function() {
              $(this).html( "<h3>Zone I</h3>Temperate climate with mean annual temperature of &le;15&#176;C and a mean annual partial water vapour pressure of &le; 11 hPa.<br><br>Confirmed Long term Stability Testing Conditions: 21&#176;C/45%RH. Minimum Duration 12 months.<br><br>Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
               ).fadeIn(500);
            });

          });

        $(".zone-two").click(function(){
            // console.log("zone two");
            $("#stability-content").fadeOut(500, function() {
              $(this).html( "<h3>Zone II</h3>Subtropical and Mediterranean climate with mean annual temperature of 15&#176;C to 22&#176;C and a mean annual partial water vapour pressure of 11 to 18 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 25&#176;C/60%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
                ).fadeIn(500);
            });
          });


        $(".zone-three").click(function(){
            // console.log("zone three");
            $("#stability-content").fadeOut(500, function() {
              $(this).html( "<h3>Zone III</h3>Hot and Dry climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &le; 15 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/35%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
               ).fadeIn(500);
            });
          });


        $(".zone-four").click(function(){
            // console.log("zone four");
            $("#stability-content").fadeOut(500, function() {
              $(this).html( "<h3>Zone IV a</h3>Hot and Humid climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &#62;15-27 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/65%RH Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months. <h3>Zone IV b</h3> Hot and very humid climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &#62;27 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/75%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
                ).fadeIn(500);
            });
          });

	      // Responsive videos (use insite <figure> element)
        $(function() {
          var $allVideos = $("iframe[src^='http://player.vimeo.com'], iframe[src^='http://www.youtube.com'], iframe[src^='https://player.vimeo.com'], iframe[src^='https://www.youtube.com'], object, embed"),
          $fluidEl = $("figure");

          $allVideos.each(function() {
            $(this)
              // jQuery .data does not work on object/embed elements
              .attr('data-aspectRatio', this.height / this.width)
              .removeAttr('height')
              .removeAttr('width');
            });

          $(window).resize(function() {

            var newWidth = $fluidEl.width();
            $allVideos.each(function() {

              var $el = $(this);
              $el
              .width(newWidth)
              .height(newWidth * $el.attr('data-aspectRatio'));

            });

          }).resize();

        });

        $(function() {
         $('#menu-archive-menu').smartmenus({
           noMouseOver: true,
           // hideOnClick: true
           subMenusSubOffsetX: 1,
           subMenusSubOffsetY: -8,
           markCurrentItem: true,
           markCurrentTree: true,
           hideOnClick: false
         });
         // $("#menu-archive-menu").smartmenus("itemActivate", $("#menu-archive-menu").find("a.current").eq( - 1));

       });

        $( "#sidebar-nav-trigger" ).click(function(){
         $( "#menu-sidebar-navigation" ).slideToggle();
       });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page



        jQuery(function ($) {
          var $window = $(window);
          var $buttonTop = $('.button-service');
          var scrollTimer;

          $buttonTop.on('click', function () {
            $('html, body').animate({
              scrollTop: $("#service-space").offset().top ,
            }, 1000);
          });

          // console.log($window.scrollTop());

          // $window.on('scroll', function () {
          //   clearTimeout(scrollTimer);
          //   scrollTimer = setTimeout(function() {
          //    if ($window.scrollTop() > 90) {
          //       $buttonTop.addClass('button-top-visible');
          //     } else {
          //       $buttonTop.removeClass('button-top-visible');
          //     }
          //   }, 250);
          // });
        });

        function alignNewsArchiveBody(){
          $(".Q1-feature .home-video").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".Q1-feature .home-video").map(function() { return $(this).height(); }));
          $(".Q1-feature").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        $(window).resize(function(){
          alignNewsArchiveBody();
        });

        $(window).load(function() {
          alignNewsArchiveBody();
        });

        // // Select all links with hashes
        // $('a[href*="#"]')
        //   // Remove links that don't actually link to anything
        //   .not('[href="#"]')
        //   .not('[href="#0"]')
        //   .click(function(event) {
        //     // On-page links
        //     if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
        //       // Figure out element to scroll to
        //       var target = $(this.hash);
        //       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        //       // Does a scroll target exist?
        //       if (target.length) {
        //         // Only prevent default if animation is actually gonna happen
        //         event.preventDefault();
        //         $('html, body').animate({
        //           scrollTop: target.offset().top
        //         }, 1000, function() {
        //           // Callback after animation
        //           // Must change focus!
        //           var $target = $(target);
        //           $target.focus();
        //           if ($target.is(":focus")) { // Checking if the target was focused
        //             return false;
        //           } else {
        //             $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
        //             $target.focus(); // Set focus again
        //           }
        //         });
        //       }
        //     }
        //   });

        // $(function() {
        //   var BV = new $.BigVideo({useFlashForFirefox:false, container:$('.homeVideobg')});
        //   var sound = true;
        //   BV.init();
        //
        //   if (Modernizr.touch) {
        //       //console.log('touch device');
        //       BV.show('http://q1scientific.com/app/uploads/homepage-hero-image.jpg');
        //   // $('.homeVideobg').css({'background-image' : 'url("http://q1scientific.com/app/uploads/homepage-hero-image.jpg")', 'background-position':'top center','background-size':'cover'});
        //   } else {
        //       //console.log('desktop device');
        //   BV.show('https://player.vimeo.com/external/254540590.sd.mp4?s=f6475344dca4f01133cfa8508c90f9c404d22309&profile_id=165', {ambient:true, doLoop:true});
        //   }

          // BV.muted = true;

          // BV.setAttribute('muted','true');

        // });

      	// if ($(window).width() > 767) {
      	//   var windowHeight = $(window).height();
      	//   //console.log(windowHeight);
      	//   $('.Q1-feature').height(windowHeight);
        //
      	// }

      	if ($(window).width() > 767) {
         setTimeout(function(){
           var compHeight = $('.compliance-feature img').height();
           $('.quality').height(compHeight - 40);

         }, 100);
       }

      	// if ($(window).width() > 767) {
      	//   $(window).resize(function(){
      	//     $('.Q1-feature').height('auto');
      	//     var windowHeight = $(window).height();
      	//     //console.log(windowHeight);
      	//     $('.Q1-feature').height(windowHeight);
      	//   });
      	// }

      	/////////////Start of jQuery Custom Scroller///////////////
      	$( window ).load(function() {
          $(".latest-news-title ul").mCustomScrollbar();
        });

        $( window ).resize(function() {
          $(".latest-news-title ul").mCustomScrollbar();
        });
      	/////////////End of jQuery Custom Scroller///////////////

        $(".graph-one").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "-80&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ultra Low Temp" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Biological and Clinical Trial Samples, DNA, RNA, Urine, Serum, Plasma. Legal and pathology samples.").fadeIn(500);
          });
        });

        $(".graph-two").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "- 20&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Freezer" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Retain and Reference Pharmaceuticals.").fadeIn(500);
          });
        });

        $(".graph-three").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "2 - 8&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Refrigerated" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("API Storage. Retain and Reference Pharmaceuticals.").fadeIn(500);
          });
        });

        $(".graph-four").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "15 - 25&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ambient" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals, Bulk storage, Heritage Storage.").fadeIn(500);
          });
        });

        $(".graph-five").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "25 - 60%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long Term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical devices.").fadeIn(500);
          });
        });

        $(".graph-six").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 65%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Intermediate and <br> Long term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical Devices.").fadeIn(500);
          });
        });

        $(".graph-seven").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 75%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long term ICH Zone IVb" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage.").fadeIn(500);
          });
        });

        $(".graph-eight").click(function(){
          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "40 - 75%RH " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Accelerated ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals Accelerated testing. Stress testing and Forced degradation studies. Medical devices.").fadeIn(500);
          });
        });


        $(".zone-one").click(function(){
      	    // console.log("zone one");
      	    $("#stability-content").fadeOut(500, function() {
              $(this).html( "<h3>Zone I</h3>Temperate climate with mean annual temperature of &le;15&#176;C and a mean annual partial water vapour pressure of &le; 11 hPa.<br><br>Confirmed Long term Stability Testing Conditions: 21&#176;C/45%RH. Minimum Duration 12 months.<br><br>Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
                ).fadeIn(500);
            });

      	  });

        $(".zone-two").click(function(){
      	    // console.log("zone two");
      	    $("#stability-content").fadeOut(500, function() {
             $(this).html( "<h3>Zone II</h3>Subtropical and Mediterranean climate with mean annual temperature of 15&#176;C to 22&#176;C and a mean annual partial water vapour pressure of 11 to 18 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 25&#176;C/60%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
              ).fadeIn(500);
           });
      	  });


        $(".zone-three").click(function(){
      	    // console.log("zone three");
      	    $("#stability-content").fadeOut(500, function() {
             $(this).html( "<h3>Zone III</h3>Hot and Dry climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &le; 15 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/35%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
              ).fadeIn(500);
           });
      	  });


        $(".zone-four").click(function(){
      	    // console.log("zone four");
      	    $("#stability-content").fadeOut(500, function() {
             $(this).html( "<h3>Zone IV a</h3>Hot and Humid climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &#62;15-27 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/65%RH Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months. <h3>Zone IV b</h3> Hot and very humid climate with mean annual temperature of &#62;22&#176;C and a mean annual partial water vapour pressure of &#62;27 hPa.<br><br> Confirmed Long term Stability Testing Conditions: 30&#176;C/75%RH. Minimum Duration 12 months.<br><br> Additional Testing Conditions: Accelerated Ambient 40&#176;C/75%RH. Minimum Duration 6 months."
               ).fadeIn(500);
           });
      	  });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'storage_conditions': {
      init: function() {
        // JavaScript to be fired on the about us page

        $(".graph-one").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "-80&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ultra Low Temp" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Biological and Clinical Trial Samples, DNA, RNA, Urine, Serum, Plasma. Legal and pathology samples.").fadeIn(500);
          });


        });

        $(".graph-two").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "- 20&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Freezer" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Retain and Reference Pharmaceuticals.").fadeIn(500);
          });


        });

        $(".graph-three").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "2 - 8&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Refrigerated" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("API Storage. Retain and Reference Pharmaceuticals.").fadeIn(500);
          });


        });

        $(".graph-four").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "20 - 25&#176;C " ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Ambient" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals, Bulk storage, Heritage Storage.").fadeIn(500);
          });


        });

        $(".graph-five").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "25 - 60%RH" ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long Term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical devices.").fadeIn(500);
          });


        });

        $(".graph-six").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 65%RH" ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Intermediate and <br> Long term ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage. Medical Devices.").fadeIn(500);
          });


        });

        $(".graph-seven").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "30 - 75%RH" ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Long term ICH Zone IVb" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals long term storage.").fadeIn(500);
          });


        });

        $(".graph-eight").click(function(){

          $("#graph-head-one").fadeOut(500, function() {
            $(this).html( "40 - 75%RH" ).fadeIn(500);
          });
          $("#graph-head-two").fadeOut(500, function() {
            $(this).html( "Accelerated ICH" ).fadeIn(500);
          });
          $('#graph-text').fadeOut(500, function() {
            $(this).html("Pharmaceuticals Accelerated testing. Stress testing and Forced degradation studies. Medical devices.").fadeIn(500);
          });


        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
